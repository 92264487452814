// import groupBy from 'lodash/groupBy'
export const state = () => ({
  data: [],
  isEmpty: false,
  matchIds: [],
  loading: false,
  error: ''
})

export const mutations = {
  set_isEmpty(state, data) {
    state.isEmpty = data
  },
  set_matchIds(state, data) {
    state.matchIds = data
    this.commit('placeBet/set_matchIds', state.matchIds)
  },
  set_data(state, { matchData = [] }) {
    const matches = []
    if (matchData) {
      matches.push({
        tab: 'Live & Upcoming',
        icon: 'mdi-motion-play-outline',
        fixtures: matchData
      })

      const { category } = this.$router.currentRoute.params
      if (
        category &&
        category !== 'horse-racing' &&
        category !== 'greyhound-racing'
      ) {
        matches.push({ tab: 'Leagues', icon: 'mdi-format-list-checks' })
      }
    }
    state.data = matches
  },
  set_error(state, data) {
    state.error = data
    this.commit('snackbar/open', {
      text: state.error,
      color: 'error'
    })
  },
  set_loading(state, data) {
    state.loading = data
  }
}

export const actions = {
  async getMarketsById(
    { commit },
    { game, competitionId, loading = true, socketData, upComing = false }
  ) {
    commit('set_matchIds', [])
    let userId
    try {
      commit('set_loading', loading)
      if (loading) {
        commit('set_data', [])
      }

      let matchData
      if (socketData) {
        matchData = socketData
      } else {
        const { data: responseData } = await this.$axios({
          method: 'post',
          baseURL: process.env.SPORTSBOOK_API_URL,
          url: 'category/getMarketIds',
          data: {
            game,
            competitionId,
            _id: userId,
            _accessToken: this.$auth.getToken('customLocal'),
            accessLevel: 'Player',
            isFancy: false,
            upComing
          }
        })
        matchData = responseData ? responseData.data : undefined

        if (responseData && responseData.data && !responseData.data.length) {
          commit('set_isEmpty', true)
          commit('set_data', [])
        }
      }

      if (matchData && matchData.length > 0 && Array.isArray(matchData)) {
        commit('set_isEmpty', false)
        const matchIds = []
        matchData.forEach((market) => {
          if (market?.betfairId) {
            matchIds.push(market.betfairId)
          }
        })

        if (matchIds.length > 0) {
          this.commit('socketRate/SET_IDS', matchIds)
          this.commit('socketBookmaker/SET_IDS', matchIds)
        }

        commit('set_matchIds', matchIds)
        if (!loading) {
          commit('set_data', [])
        }

        // Placing 'Live' matches first and 'Upcoming' matches later in sorting order.
        const reorderedMatchData = [
          ...matchData.filter((item) => item.inPlayStatus === 'Open'),
          ...matchData.filter((item) => item.inPlayStatus !== 'Open')
        ]

        commit('set_data', { matchData: reorderedMatchData })
      } else if (!matchData?.length) {
        commit('set_isEmpty', true)
        commit('set_data', [])
      } else if (!matchData) {
        commit('set_error', 'Error getting matches')
      }
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error.message || 'Error fetching data')
      commit('set_loading', false)
    }
  }
}
